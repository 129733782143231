.formContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 10px;
  z-index: 10;
}

@media (max-width: 767px) {
  .formContainer {
    justify-content: center;
  }
}

.circleButton {
  width: 60px;
  height: 60px;
  font-size: 24px;
  font-weight: 700;
  border-radius: 50%;
  border: none;
  outline: none;
  background-color: #40434e;
  color: #9da6b1;
  cursor: pointer;
  display: inline-flex;
    justify-content: center;
    align-items: center;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out, font-weight 0.1s ease-in-out, color 0.3s ease-in-out;
}

@media (max-width: 660px) {
  .circleButton {
    width: calc(80vw / 7);
    height: calc(80vw / 7);
    font-size: calc(45vw / 7);
  }
}

.logSetButton {
  width: 50px;
  height: 50px;
  font-size: 22px;
  font-weight: 700;
  border-radius: 50%;
  
  border: none;
  outline: none;
  background-color: #f96c22;
  color: #f8f8f8;
  cursor: pointer;
  display: inline-flex;
    justify-content: center;
    align-items: center;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out, font-weight 0.1s ease-in-out, color 0.3s ease-in-out;
}

@media (max-width: 660px) {
  .logSetButton {
    width: calc(80vw / 8);
    height: calc(80vw / 8);
    font-size: calc(45vw / 8);
  }
}

.buttonEyeIcon {
  width: 25px;
    height: 25px;
}

@media (max-width: 660px) {
  .buttonEyeIcon {
    width: calc(45vw / 8);
    height: calc(45vw / 8);
    
  }
}
.paddingToLeft {
  padding-left: 10px;
    
}

@media (max-width: 660px) {
  .paddingToLeft {
    padding-left: 2%;
    
  }
}
.paddingToLeftFirst {
  padding-left: 15px;
    
}

@media (max-width: 660px) {
  .paddingToLeftFirst {
    padding-left: 3%;
    
  }
}


.formContentDiv {
  display: flex;
justify-content: center;
align-items: center;
border-radius: 30px;
margin-top: 32px;
height: 87%;
width: 95%;
background-color: #001E1E;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
z-index: 3;
overflow-y: auto;
}

@media (max-height: 500px) {
  .formContentDiv {
    height: 70%;
  }
}
.fade-in {
  opacity: 1;
}

.fade-out {
  transition: opacity 0.5s ease-out;
  opacity: 0;
}
