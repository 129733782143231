.formContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 10px;
  z-index: 10;
}

@media (max-width: 767px) {
  .formContainer {
    justify-content: center;
  }
}


.formContentDiv {
  display: flex;
justify-content: center;
align-items: center;
border-radius: 10px;
margin-top: 30px;
height: 87%;
width: 95%;
background-color: #001E1E;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
z-index: 4;
}

@media (max-height: 500px) {
  .formContentDiv {
    height: 70%;
  }
}
